<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid grid-list-md>
            <v-layout row wrap>

                <v-flex d-flex xs12 sm8 md12 lg12 xl12>
                    <v-layout row wrap>

                        <v-flex xs12 sm12 md6 lg6 xl6>
                            <v-select v-model="productmodel.product_brand_id" :items="loadProductBrands" item-text="prod_b_nome" item-value="prod_b_id" label="Selecione uma Marca..." :rules="rules.contentSectionRules" required outline></v-select>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg6 xl6>
                            <v-text-field v-model="productmodel.prod_mo_nome" :rules="rules.nomeRules" label="Nome" outline required></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg6 xl6>
                            <v-text-field v-model="productmodel.prod_mo_descricao" label="Descrição" outline required></v-text-field>
                        </v-flex>

                        <v-flex xs12>
                            <v-switch v-model="productmodel.prod_mo_status" label="Status"></v-switch>
                        </v-flex>

                        <v-flex xs12>
                            <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                                {{btnLabel}}
                            </v-btn>
                        </v-flex>

                    </v-layout>
                </v-flex>

            </v-layout>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    name: "FormProductModelComponent",
    created() {
        console.log("PASSOU AQUI")
        this.$store.dispatch('loadProductBrands');
    },
    computed: {
        loadProductBrands() {
            return this.$store.state.productbrands.items.data;
        }
    },
    props: {
        productmodel: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    prod_mo_id: '',
                    product_brand_id: '',
                    prod_mo_nome: '',
                    prod_mo_slug: '',
                    prod_mo_descricao: '',
                    prod_mo_logo: '',
                    prod_mo_status: false,
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    data() {
        return {
            valid: true,
            rules: {
                nomeRules: [
                    v => !!v || 'Campo Nome é obrigatório',
                    v => (v && v.length <= 100) || 'Nome precisa ter até 100 Caracteres'
                ]
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {

            if (this.$refs.form.validate()) {
                const action = this.update ? 'updateProductModel' : 'storeProductModel';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                this.$store.dispatch(action, this.productmodel)
                    .then(() => {
                        this.$swal({
                            position: "center",
                            type: "success",
                            title: msg,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.$router.push({
                            name: 'product-models'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            type: "error",
                            title: 'Erro',
                            text: 'Algo deu errado',
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    type: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }

        },
    },
    components: {

    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
    height: 120px;
}
</style>
