<template>

    <span>
    <v-layout mb-4>
      <v-flex xs10 >
        <h2 class="lime--text lighten-1 pt-3">Gerenciar Modelos - Inclusão</h2>
      </v-flex>
      <v-flex xs2 text-xs-right>
        <v-btn
          color="blue-grey"
          class="black--text"
          :to="{name: 'product-models'}"
        >
          <v-icon dark>keyboard_arrow_left</v-icon>
          Voltar
        </v-btn>
      </v-flex>
    </v-layout>
        <form-product-model :btnLabel="btnLabel"></form-product-model>
    </span>
        
</template>

<script>
import FormProductModelComponent from "./partial/FormProductModelComponent";

export default {
  name: "AddProductModelComponent",
  data () {
    return {
        btnLabel: 'Incluir'
    }
  },
  methods: {

  },
  components: {
    formProductModel: FormProductModelComponent,
  }
};
</script>

<style scoped>

</style>
